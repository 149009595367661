import { GestionAvanceProcesosReactiveDialog } from './components/gestionavanceprocesosreactive/gestionavanceprocesosreactive.dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './app.shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RecuperarAccesoDialog } from './login/recuperaracceso/recuperaracceso.dialog';
import { BlockUIModule } from 'ng-block-ui';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { CambiarClaveDialog } from './login/cambiarclave/cambiarclave.dialog';
import { ConfirmarCorreoCliente } from './components/confirmarcorreocliente/confirmarcorreocliente-component';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginatorIntl, MAT_DATE_LOCALE } from '@angular/material';
import { notificationSocketComponent } from './components/notification-socket/notification-socket.component';
import { RecuperarAccesoComponent } from './mantenimiento/gr-autorizacion/recuperaracceso/recuperaracceso-component';
import { SideNavMenuComponent } from './nav-menu/side-nav/side-nav-menu.component';
import { NotificacionDialog } from './mensajeria/gr-gestion/notificacion/notificacion.dialog';
import { ProcesosAutomaticosTable } from './nav-menu/procesosautomaticos/procesosautomaticos.table';
import { MatPaginatorI18nService } from './components/paginator.i18n.provider';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { GestionAvanceProcesosTable } from './components/gestionavanceprocesos/gestionavanceprocesos.table';
import { GestionAvanceProcesosDialog } from './components/gestionavanceprocesos/gestionavanceprocesos.dialog';
import { HorarioComponent } from './components/horario/horario.component';

import { GestionAvanceProcesosReactiveTable } from './components/gestionavanceprocesosreactive/gestionavanceprocesosreactive.table';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    ConfirmarCorreoCliente,
    CambiarClaveDialog,
    RecuperarAccesoDialog,
    RecuperarAccesoComponent,
    SideNavMenuComponent,
    NotificacionDialog,
    notificationSocketComponent,
    ProcesosAutomaticosTable,
    GestionAvanceProcesosTable,
    GestionAvanceProcesosDialog,
    HorarioComponent,
    GestionAvanceProcesosReactiveTable,
    GestionAvanceProcesosReactiveDialog
  ],
  entryComponents: [CambiarClaveDialog,
    RecuperarAccesoDialog,
    notificationSocketComponent,
    NotificacionDialog,
    ProcesosAutomaticosTable,
    GestionAvanceProcesosTable,
    GestionAvanceProcesosDialog,
    HorarioComponent,
    GestionAvanceProcesosReactiveTable,
    GestionAvanceProcesosReactiveDialog
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgCircleProgressModule.forRoot({}),
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ChartsModule,
    RecaptchaModule,
    SharedModule.forRoot(),
    NgCircleProgressModule.forRoot({})
  ],
  bootstrap: [AppComponent],
  providers: [SideNavMenuComponent,
    CurrencyPipe,
    notificationSocketComponent, ProcesosAutomaticosTable,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}
