import { Component, ViewChild, HostListener, Input, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { FormGroup, FormBuilder, ValidationErrors, FormControl } from '@angular/forms';
import { funcionalidadesModel } from '../../modulos/funcionalidades.model';
import { isnull } from '../../components/powerbuilder.functions';
import { moduloModel } from '../../modulos/modulo.model';
import { startWith, switchMap, map, ignoreElements } from 'rxjs/operators';
import { funcionalidadesService } from '../../modulos/funcionalidades.service';
import { subirAnimationMenu } from '../../animations/animations';
import { Observable, Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { moduloService } from '../../modulos/modulo.service'
import { AppService } from '../../app.service';
import * as jwt_decode from 'jwt-decode';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CambiarClaveDialog } from '../../login/cambiarclave/cambiarclave.dialog';
import { loginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material';
import { Location } from '@angular/common';
import { notificationSocketComponent } from '../../components/notification-socket/notification-socket.component';
import { NotificacionDialog } from '../../mensajeria/gr-gestion/notificacion/notificacion.dialog';
import { NotificationComunicationService } from '../../services/notificacionescomunication.service';
import { UrlChangeService } from 'src/app/services/urlchange.service';
import { isUndefined } from 'util';
// import { IncidenciasComponent } from '../../components/incidencias/incidencias.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ProcesosAutomaticosTable } from '../procesosautomaticos/procesosautomaticos.table';

declare var appComponent: any;
declare var videoTutorial: boolean;

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.css'],
  providers: [funcionalidadesService],
  animations: [subirAnimationMenu]
})
export class SideNavMenuComponent implements OnInit, AfterViewInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('sidenav', { static: false }) public sidenav: MatSidenav;
  socket: notificationSocketComponent;
  get usuario(): any {
    let _usuario;
    try {
      _usuario = jwt_decode(sessionStorage.getItem('token'));
    } catch (error) {
      _usuario = {};
    }
    return _usuario;
  }
  private urlm: string;
  activeLang: string;
  activeImg: string;
  filtroForm: FormGroup;
  _search = false;
  _panel = false;
  opened = false;
  verIcono = true;
  _funcionShow = false;
  _funcionPanel = true;
  _funcionModulo = true;
  itemActivo: funcionalidadesModel;
  moduloActivo: moduloModel;
  selectedModulo: moduloModel = null;
  selectedGrupos = '';
  filteredFuncionalidades: funcionalidadesModel[];
  grupos: Array<string> = [];
  filteredFuncionalidades_Identity: Array<any> = [];
  modulos: Array<moduloModel> = [];
  _gruposShow = false;
  userTokenData;
  filteredModules: Observable<moduloModel[]>;
  myControl = new FormControl();
  selectedFuncionalidades: funcionalidadesModel = null;
  loadingModules = true;
  loadingFuncionalidades = false;
  _funcionGrupo = false;
  _funcionalidad = false;
  tipoLogoMenu = 'more_vert';
  loadingRouteConfig: boolean;
  verLista = false;
  usuarioDatos: any[];
  tipoVista = 0;
  funcionalidades: Array<funcionalidadesModel> = [];
  numberNotificacion = '0';
  rows: [] = [];
  subscription: Subscription;
  videoForm: FormGroup;
  urlVideo: any;
  permitedRolUrlList = [];
  permitedUrlErrorMessage = 'Url Error';
  modoSideNav;
  desktop;

  fechaSistema: string = "Fecha del Sistema: ";

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private builder: FormBuilder,
              private funcionalidadesService: funcionalidadesService,
              private moduloService: moduloService,
              private appService: AppService,
              public dialog: MatDialog,
              private loginService: loginService,
              private translate: TranslateService,
              private location: Location,
              private urlChangeService: UrlChangeService,
              private sanitizer: DomSanitizer,
              private _snackBar: MatSnackBar,
              private notificationComunicationService: NotificationComunicationService) {

    this.funcionalidadesService.getFechaSistema().subscribe(data => {
      if (data.fechaDialogo) {
        let _data = data.fechaDialogo.split('-');
        this.fechaSistema = `${this.fechaSistema} ${_data[2]}-${_data[1]}-${_data[0]}`;
      };
    })

    translate.get('Main').subscribe(rst => {
      this.permitedUrlErrorMessage = rst.urlNotPermitted;
    });
    this.onResize(event);
    this.subscription = this.notificationComunicationService.getChanges().subscribe(activate => {
      if (activate) {
        this.notificacionSocket();
      }
    });

    urlChangeService.listenUrl().subscribe(ruta => {
      if (isUndefined(this.itemActivo) && isUndefined(this.moduloActivo) && ruta && ruta !== '/') {
        this.itemActivo = new funcionalidadesModel();
        this.itemActivo.funcionalidadNombre = sessionStorage.getItem('Funcionalidad');
        this.itemActivo.grupo = sessionStorage.getItem('Grupo');
        this.moduloActivo = new moduloModel();
        this.moduloActivo.moduloNombre = sessionStorage.getItem('Modulo');
        this._funcionShow = true;
      }
      if (this.permitedRolUrlList.length === 0) {
        this.funcionalidadesService.getUrlListPermited(this.usuario.rl).subscribe(rst => {
          this.permitedRolUrlList = rst;
          this.isUrlPermited(ruta);
        });
      } else {
        this.isUrlPermited(ruta);
      }

    });
    this.activeLang = 'es-mx';
    this.activeImg = 'btn-lang-mx';
    this.filtroForm = this.builder.group({
      'filtroTexto': [''],
      'filtroTexto_codigo': [''],
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        const validationErrors = {};
        return validationErrors;
      }
    });

    this.videoForm = formBuilder.group({
      videotutorial: false,
    });
  }

  ngOnInit() {
    if (this.location.path() !== '/') {
      setTimeout(() => { this._funcionPanel = true; }, 10)
      this._panel = true;
    } else {
      setTimeout(() => { this._funcionPanel = true; }, 10)
      this._panel = false;
    }

    this.filtroForm.valueChanges.subscribe((data) => {
      this.filtroForm.patchValue({
      }, { emitEvent: false, onlySelf: true });
    });

    this.filtroForm.controls.filtroTexto.valueChanges
      .pipe(
        startWith(''),
        switchMap((data) => {
          if (data) {
            return this.funcionalidadesService.filterFuncionalidad_Identity(data, Number(this.usuario.rl));
          } else {
            return [];
          }
        })
      ).subscribe((data) => {
        this.filteredFuncionalidades_Identity = data.rows;
      });

    this.notification();
    this.socket = new notificationSocketComponent(this._snackBar);

    this.videoForm.controls.videotutorial.valueChanges
      .subscribe(() => {
        videoTutorial = !videoTutorial;
      })
  }

  ngAfterViewInit() {
    this.notification();
    this.loadingModules = true;
    this.loadNavigation();
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loadingRouteConfig = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loadingRouteConfig = false;
        }
      });
  }

  isUrlPermited(ruta) {
    if (ruta !== '/') {
      const filteredRutaApp = ruta.split('app');
      const filteredRutaParams = filteredRutaApp[1].split('?');
      const permitedList = this.permitedRolUrlList.filter(modulo => modulo.sentenciaSql === filteredRutaParams[0]);
      if (permitedList.length <= 0) {
        this.openNotificationDanger(this.permitedUrlErrorMessage);
        this.volverMenu();
        console.log("No hay permisos a esta funcionalidad -> " + filteredRutaParams[0]);
      }
    } else {
      this.blockUI.stop();
    }
  }
  volverMenu() {
    this.router.navigateByUrl('/');
    this.filtroForm.patchValue({ filtroTexto: '' });
    this._search = false;
    this._panel = false;
    this.opened = false;
    this.verIcono = true;
    this._funcionShow = false;
    this._funcionPanel = true;
    this._funcionModulo = true;
    this.itemActivo = new funcionalidadesModel();
    this.itemActivo.funcionalidadNombre = '';
    if (!isnull(this.moduloActivo)) { this.moduloActivo._funcionalidadesShow = false; }
    this.selectedModulo = new moduloModel();
    this.selectedModulo.moduloNombre = '';
    this.selectedGrupos = '';
    this.grupos = [];
    if (!isnull(this.selectedModulo)) {
      this.selectedModulo._funcionalidadesShow = false;
      this.selectedModulo._funcionalidades = false;
    }
    this.filteredFuncionalidades = [];
  }

  public setModulos(modulos: Array<moduloModel>) {
    this.loadingModules = false;
    this.selectedModulo = null;
    modulos.forEach(element => {
      element.rutaImagen = element.rutaImagen.replace('.png', '.webp');
    });
    this.modulos = modulos;
  }

  selectModuloPanel(e, modulo: moduloModel) {
    this.opened = false;
    this._search = false;
    this.verIcono = true;
    this.selectModulo(e, modulo);
  }

  selectModulo(e, modulo: moduloModel) {
    if (this.selectedModulo === modulo) {
      this.selectedModulo._funcionalidadesShow = !this.selectedModulo._funcionalidadesShow;
    } else {
      if (this.selectedModulo) {
        this.selectedModulo._funcionalidadesShow = false;
      }
      this.selectedModulo = modulo;
      this.moduloActivo = modulo;
      modulo._funcionalidades = true;
      this.loadingFuncionalidades = true;
      this.blockUI.start('Cargando...');
      this.funcionalidadesService.getFuncionalidades(modulo).subscribe((data) => {
        this.blockUI.stop();
        if (!data.error) {
          this.funcionalidades = data;
          this.loadingFuncionalidades = false;
          modulo._funcionalidades = true;
          modulo._funcionalidadesShow = true;
          this.selectedGrupos = null;
          this._gruposShow = false;
          this.grupos = [...new Set(this.funcionalidades.map((x) => x.grupo))];
          this.selectedGrupos = '';
          this._funcionGrupo = true;
        } else {
          this.selectedModulo = null;
          this.openNotificationDangerLargo("Su session ha caducado, por favor vuelva a ingresar al sistema.")
          setTimeout(() => {
            this.cerrarSesion();
          }, 2000)
        }

      });
    }
    this._funcionModulo = false;
    this._funcionalidad = false;
    this._search = false;
    this.filteredFuncionalidades = [];
  }

  selectGrupo(e, grupo: string) {
    if (this.selectedGrupos === grupo) {
      this._gruposShow = !this._gruposShow;
    } else {
      this._gruposShow = true;
      this.selectedGrupos = grupo;
      this.filteredFuncionalidades = this.funcionalidades.filter((x) => x.grupo === this.selectedGrupos);
    }
    this._funcionalidad = true;
  }

  selectFuncionalidad(e, modulo: moduloModel, funcionalidad: funcionalidadesModel) {
    this._funcionPanel = false;
    window['funcionalidadActiva'] = { modulo, funcionalidad };
    this.urlm = this.selectedGrupos.replace(/ /g, '');
    this.urlm = this.urlm.toLowerCase();
    this.urlm = this.MaysPrimera(this.urlm);
    this.router.navigateByUrl(`/app${this.moduloActivo.rutaEjecutable}/${this.urlm}/${funcionalidad.ventanaNombre}`);
    this.itemActivo = funcionalidad;
    this._panel = true;
    this.retrasoRuta();
    sessionStorage.setItem('Modulo', this.moduloActivo.moduloNombre);
    sessionStorage.setItem('Grupo', this.itemActivo.grupo);
    sessionStorage.setItem('Funcionalidad', this.itemActivo.funcionalidadNombre);
    if (!this.desktop) { this.opened = false; };
  }

  onSelectFuncionalidad_busqueda(opt: any, panel?: boolean) {
    if (panel) { this._panel = panel; }
    this._funcionPanel = false;

    this.modulos.forEach(modulo => {
      if (modulo.moduloCodigo === opt.moduloCodigo && modulo !== this.selectedModulo) {
        this.selectModulo('', modulo);
      }
    });
    this.blockUI.start('Cargando...');
    this.funcionalidadesService.getFuncionalidades(this.moduloActivo).subscribe((data) => {
      this.blockUI.stop();
      if (!data.error) {
        data.forEach(funcionalidad => {
          if (funcionalidad.funcionalidadCodigo === opt.funcionalidadCodigo) {
            this.itemActivo = funcionalidad;
          }
        });
        if (this.itemActivo.grupo !== this.selectedGrupos) { this.selectGrupo('', this.itemActivo.grupo); }
        this.router.navigateByUrl(`/app${this.moduloActivo.rutaEjecutable}/${this.itemActivo.grupo}/${this.itemActivo.ventanaNombre}`);
        this.retrasoRuta();
      }
    });
  }

  loadNavigation() {
    if (sessionStorage.getItem('token') && this.modulos.length < 1) {
      this.blockUI.start('Cargando...');
      this.moduloService.getModulos().subscribe((data) => {
        this.blockUI.stop();
        if (data instanceof Array) {
          this.modulos = data;
          this.filteredModules = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
          this.setModulos(data);
        }
      });
    } else if (this.modulos.length > 0) {
      this.loadingModules = false;
    }
  }


  private _filter(value: string): moduloModel[] {
    const filterValue = value.toString().toLowerCase();
    return this.modulos.filter(_module => _module.moduloNombre.toLowerCase().includes(filterValue));
  }


  volverHome() {
    this.router.navigateByUrl('/');
    this.filtroForm.patchValue({ filtroTexto: '' });
    this._search = false;
    this._funcionShow = false;
    this._funcionModulo = false;
    this._funcionalidad = true;
    this._funcionPanel = true;
  }

  getUserTokenInfo() {
    this.blockUI.start('Cargando Información...'); // Start blocking
    if (sessionStorage.getItem('token')) {
      this.userTokenData = jwt_decode(sessionStorage.getItem('token'));
      if (this.userTokenData) {
        this.appService.getByIdIdentityRol(Number(this.userTokenData.rl)).subscribe((rolDetail) => {
          this.appService.getEstructura(Number(this.userTokenData.cssr)).subscribe((sucursalDetail) => {
            this.blockUI.stop();
            if (rolDetail.error == null) { this.userTokenData.rolDetail = rolDetail; };
            if (sucursalDetail.error == null) { this.userTokenData.sucursalDetail = sucursalDetail; };
            this.usuarioDatos = [
              { valor: this.userTokenData.gvn, fondo: '#2874A6', colorLetra: 'white' },
              { valor: `Usuario: ${this.userTokenData.sub}`, fondo: '#F7F9F9', colorLetra: 'black' },
              // { valor: `Correo: prueba@tecnoevolucion.com`, fondo: '#F7F9F9', colorLetra: 'black' },
              { valor: `Rol: ${this.userTokenData.rolDetail.rolNombre}`, fondo: '#F7F9F9', colorLetra: 'black' },
              { valor: `Firma WFC: Nivel ${this.userTokenData.frm}`, fondo: '#F7F9F9', colorLetra: 'black' },
              {
                valor: `Suc.${this.userTokenData.sucursalDetail.CODIGO_SUCURSAL} - ${this.userTokenData.sucursalDetail.NOMBRE_SUCURSAL}`,
                fondo: '#3283A3', colorLetra: 'white'
              },
              {
                valor: `Reg.${this.userTokenData.sucursalDetail.codigo_region} - ${this.userTokenData.sucursalDetail.nombre_region}`,
                fondo: '#3283A3', colorLetra: 'white'
              },
              {
                valor: `Div.${this.userTokenData.sucursalDetail.codigo_division} - ${this.userTokenData.sucursalDetail.nombre_division}`,
                fondo: '#3283A3', colorLetra: 'white'
              },
              {
                valor: `Dir.${this.userTokenData.sucursalDetail.codigo_direccion} - ${this.userTokenData.sucursalDetail.nombre_direccion}`,
                fondo: '#3283A3', colorLetra: 'white'
              },
              {
                valor: `Ger.${this.userTokenData.sucursalDetail.codigo_gerencia} - ${this.userTokenData.sucursalDetail.nombre_gerencia}`,
                fondo: '#3283A3', colorLetra: 'white'
              }, {
                valor: `${this.fechaSistema}`,
                fondo: '#1D8348', colorLetra: 'white'
              }
            ];
            this.tipoVista = 1;
          });
        });
      }
    }
  }

  closeUserTokenInfo() {
    this.tipoVista = 0;
  }

  cambioClave() {
    if (sessionStorage.getItem('token')) {
      this.userTokenData = jwt_decode(sessionStorage.getItem('token'));
      if (this.userTokenData) {

        const dialogRef = this.dialog.open(CambiarClaveDialog, {
          data: {
            username: this.userTokenData.sub,
            password: '',
            passwordnew: ''
          }
        });
        dialogRef.afterClosed().subscribe(result => { });
      }
    }
  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  showRouterOutlet() {
    if (this.router.url === '/') {
      if (this.showBodyMenu()) {
        return false;
      } else {
        if (this.showModuleOptMenu()) {
          return false;
        } else {
          return this._funcionModulo;
        }
      }
    } else {
      return true;
    }

  }
  showModuleOptMenu() {
    return !this._funcionModulo;
  }

  showBodyMenu() {
    if (this.router.url === '/') {
      this._funcionPanel = true;
      return this._funcionModulo;
    } else if (this.location.path() !== '/') {
      this._funcionPanel = false;
      return this._funcionModulo;
    } else {
      return false;
    }
  }
  public cerrarSesion() {
    this.blockUI.start('Cargando...');
    this.loginService.logout().subscribe((resp) => {
      this.blockUI.stop();
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('isLogin');
      this.router.navigate(['']);
    });

  }

  cambiarIconoMenu() {
    this.verIcono = !this.verIcono;
    if (this.tipoLogoMenu === 'more_vert') {
      this.verLista = false;
      this.tipoLogoMenu = 'menu';
    } else {
      setTimeout(() => {
        this.verLista = true;
      }, 1);
      this.tipoLogoMenu = 'more_vert';
    }
  }

  retrasoRuta() {
    setTimeout(() => {
      this._funcionShow = true;
    }, 1000);
  }

  setLanguage(language: string, img: string) {
    this.activeLang = language;
    this.activeImg = img;
    this.translate.use(language);
    this.tipoVista = 0;
  }

  notification() {
    this.funcionalidadesService.getNumberNotification().subscribe(
      (resp: any) => {
        this.numberNotificacion = resp > 99 ? '99+' : resp;
      }
    );
  }

  notificacionSocket() {
    this.notification();
    //this.listNotification();
  }

  vistaNotificacion() {
    this.blockUI.start('Cargando Información...'); // Start blocking
    if (this.tipoVista !== 2) {
      this.funcionalidadesService.listNotification(String(appComponent.usuario.sub)).subscribe(
        (resp: any) => {
          this.rows = resp.rows;
          this.blockUI.stop();
          this.tipoVista = 2;
        }
      );
    }
  }

  vistaIdiomas() {
    this.tipoVista = 3;
  }

  listNotification() {
    if (this.tipoVista !== 2) {
      this.funcionalidadesService.listNotification(String(appComponent.usuario.sub)).subscribe(
        (resp: any) => {
          this.rows = resp.rows;
        }
      );
    }
  }

  detailNotification(notification: any) {
    const dialogRef = this.dialog.open(NotificacionDialog, {
      data: notification
    });
    dialogRef.afterClosed().subscribe(result => {
      this.notification();
      notification.estado = 1;
    });
  }

  viewNotification() {
    this.tipoVista = 0;
    this.router.navigate(['/app/mensajeria/Gestion/GestionNotificacion']);
  }

  viewMasivos() {
    this.router.navigate(['/app/parametros/Reportes/ProcesosMasivos']);
  }

  viewAutomaticos() {
    const dialogRef = this.dialog.open(ProcesosAutomaticosTable, {

    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openAsignaciones() {
    // Desabilitado para portal

    // const dialogRef = this.dialog.open(IncidenciasComponent, {
    //   data: {}
    // });
    // dialogRef.afterClosed().subscribe(result => { });
  }

  get notificaciones(): string {
    return Number(this.numberNotificacion) > 0 || this.numberNotificacion === '99+' ? this.numberNotificacion : '';
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  openNotificationDangerLargo(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'dangerSnackBar',
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 850) {
      this.modoSideNav = 'over';
      this.desktop = false;
    } else {
      this.modoSideNav = 'side'
      this.desktop = true;
    }
  }

}
